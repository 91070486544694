<template>
  <div>
    <div
      v-if="showUserInfo"
      class="usr-info"
    >
      <h1 class="mt-4">ユーザー情報</h1>
      <h2>
        NAME: {{ name }}
      </h2>
      <h3 class="mt-5">
        ユーザー情報が間違っている場合は、<a
          color="blue darken-1"
          text
          @click="logout"
        >
          再度ログイン
        </a>を行ってください。
      </h3>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="cannotFind"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            ログインを行ってください
          </v-card-title>
          <v-card-text>ユーザー情報が見つかりませんでした。<br />再度ログインを行ってください。</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Home',
  components: {
    //
  },
  data: () => ({
    cannotFind: false,
    showUserInfo: true,
    name: 'Jhone Doe',
    id: '00000'
  }),
  created () {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user
      if (user) {
        this.showUserInfo = true
        console.log('finish OAuth')
        this.cannotFind = false
        const info = this.$store.state.userInfo
        this.name = info.displayName
        this.id = info.uid
        this.$store.commit('setUserId', this.id)
        this.getData()
        // this.check()
      } else {
        // not login yet
        this.cannotFind = true
      }
    })
  },
  methods: {
    check () {
      const info = this.$store.state.userInfo
      if (!Object.keys(info).length) {
        this.cannotFind = true
      } else {
        this.name = info.getName()
        this.id = info.getId().toString()
      }
    },
    async login () {
      try {
        const provider = new firebase.auth.GoogleAuthProvider()
        firebase.auth().signInWithPopup(provider).then((result) => {
          this.$store.commit('setUserInfo', result.user)
        })
      } catch (error) {
        console.error(error)
        return null
      }
    },
    async logout () {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
        console.error(error)
      })
      this.$store.commit('setUserInfo', {})
      window.location.href = '/'
    },
    getData () {
      const db = firebase.database()
      const ref1 = db.ref('class/' + this.id)
      const ref2 = db.ref('timer/' + this.id)

      ref1.once('value', (snap) => {
        this.$store.state.classInfo = []
        const cList = snap.val()
        for (const key in cList) {
          const data = cList[key]
          this.$store.commit('pushClassData', { data, key })
        }
      }, (err) => {
        console.error(err)
      })

      ref2.once('value', (snap) => {
        this.$store.commit('pushTimerData', { data: snap.val() })
      }, (err) => {
        console.error(err)
      })
    }
  }
}
</script>

<style>
.usr-info {
  margin: 30px 40px;
}
.route {
  text-align: center;
}
</style>
