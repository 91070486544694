<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        class="cardList overflow-y-auto"
      >
        <v-col
          cols="12"
          xs="12"
          sm="10"
        >
          <v-card
            class="card"
            v-for="data in classList"
            v-bind:key="data.key"
          >
            <v-card-title>{{ data.name }}</v-card-title>
            <v-card-actions>
              <v-btn
                text
                color="primary"
                @click="select(data)"
              >
                Use This
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        class="randomName"
      >
        <v-col v-if="className != ''">
          <h2>{{ className }} を表示しています</h2>
          <v-col>
            <v-chip
              v-for="m in classMember"
              v-bind:key="m"
              class="chosenList"
            >
              {{ m }}
            </v-chip>
            <h3 style="margin-top:20px;">既出リスト</h3>
            <v-col v-if="!alreadyList == []">
              <v-chip
                v-for="a in alreadyList"
                v-bind:key="a"
                class="chosenList"
              >
                {{ a }}
              </v-chip>
            </v-col>
            <v-col v-else>
              <h2>空だよ</h2>
            </v-col>
          </v-col>
        </v-col>
        <v-col v-else>
          <h1>参照するクラスを<br>選択してください</h1>
          <br>
          <h2>一覧に無い場合は<br><router-link to="/edit">ここ</router-link> から追加してください</h2>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'List',
  computed: {
    classList () { return this.$store.state.classInfo }
  },
  data: () => ({
    className: '',
    classMember: [],
    alreadyList: []
  }),
  methods: {
    select (data) {
      if (!this.className === '') {
        this.resetList()
      }
      this.className = data.name
      this.classMember = data.mem
      this.alreadyList = data.already
    },
    resetList () {
      this.className = ''
      this.classMember = []
      this.alreadyList = []
    }
  }
}
</script>

<style>

</style>
