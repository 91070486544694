<template>
  <header>
    <v-app-bar
      app
      dark
      clipped-left
      color="green darken-3"
    >
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Yama System</v-toolbar-title>
      <template v-slot:extension>
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab
            v-for="(menuItem, index) in menuItems"
            :key="index"
          >
            {{ menuItem.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Navigation
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <div v-for="(menuItem, i) in menuItems" :key="i">

          <v-list-item
            v-if="!menuItem.subLinks"
            :to="menuItem.url"
            avatar
            class="v-list-item"
          >
            <v-list-item-icon>
              <v-icon>{{ menuItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="menuItem.name" />
          </v-list-item>

          <v-list-group
            v-else
            :key="menuItem.name"
            no-action
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-folder</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in menuItem.subLinks"
              :to="sublink.url"
              :key="sublink.name"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sublink.name }}</v-list-item-title>

            </v-list-item>

          </v-list-group>

        </div>

      </v-list>
    </v-navigation-drawer>
  </header>
</template>

<script>
import constants from '../common/constants'

export default {
  name: 'AppHeader',
  data () {
    return {
      tab: null,
      drawer: false,
      menuItems: constants.menuItems
    }
  },
  watch: {
    tab () {
      if (this.$router.currentRoute.path === this.menuItems[this.tab].url) {
        return
      }
      this.$router.push(this.menuItems[this.tab].url)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/common/common.scss";

.v-toolbar__title {
  overflow: visible !important;
  margin-right: 50px !important;
}

.v-app-bar__nav-icon {
  @include display_tab {
    display: none !important;
  }
}

.v-tabs {
  display: none;

  @include display_pc {
    display: block !important;
  }
}
</style>
