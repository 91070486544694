import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Random from '../views/Random.vue'
import Edit from '../views/Edit.vue'
import List from '../views/List.vue'
import Docs from '../views/Docs.vue'
import FeedBack from '../views/FeedBack.vue'
import Timer from '../views/Timer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/random',
    name: 'Random',
    component: Random
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/edit',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: FeedBack
  },
  {
    path: '/timer',
    name: 'Timer',
    component: Timer
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
