import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'

Vue.config.productionTip = false

// Initialize Firebase
var config = {
  apiKey: 'AIzaSyCFJXKgE_sr7cUbA_cBYS7SjS56aX29fhw',
  authDomain: 'randomer-2ff39.firebaseapp.com',
  databaseURL: 'https://randomer-2ff39-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'randomer-2ff39',
  storageBucket: 'randomer-2ff39.appspot.com',
  messagingSenderId: '476365356461'
}
firebase.initializeApp(config)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
