<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="5"
        class="cardList overflow-y-auto"
      >
        <v-col
          cols="12"
          xs="12"
          sm="11"
        >
          <v-card
            class="my-4 card"
            v-for="data in classList"
            v-bind:key="data.key"
          >
            <v-card-title>{{ data.name }}</v-card-title>
            <v-card-actions>
              <v-btn
                text
                color="primary"
                @click="select(data)"
              >
                Use This
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="7"
        class="randomName mt-10"
        max-height="200"
      >
        <v-col v-if="className != ''">
          <h2 class="mb-4" style="color: #424242">{{ className }} から抽選します</h2>
          <h1 v-if="roulette" style="color: #212121">{{ rouletteMember }}</h1>
          <h1 v-else class="chosen">{{ chosenMember }}</h1>
          <v-btn
            class="random-btn ma-3"
            depressed
            color="primary"
            dark
            @click="randomChoose"
          >
            Choose
          </v-btn>
          <v-btn
            class="random-btn ma-3"
            depressed
            color="amber darken-3"
            dark
            @click="saveList"
          >
            SAVE
          </v-btn>
          <br>
          <v-btn
            class="random-btn ma-3"
            depressed
            color="green darken-2"
            dark
            @click="showAlready = true"
          >
            Show List
          </v-btn>
          <v-btn
            class="random-btn ma-3"
            depressed
            color="red darken-1"
            dark
            @click="showAlready = false"
          >
            Hide List
          </v-btn>
          <div v-if="showAlready">
            <h3>既出リスト</h3>
            <v-chip
              v-for="n in alreadyList"
              v-bind:key="n"
              class="chosenList"
            >
              {{ n }}
            </v-chip>
          </div>
        </v-col>
        <v-col v-else>
          <h1>使用するクラスを<br>選択してください</h1>
          <br>
          <h2>一覧に無い場合は<br><router-link to="/edit">ここ</router-link> から追加してください</h2>
        </v-col>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="saveComp"
        max-width="300px"
      >
        <v-card>
          <v-card-title class="text-h5">
            保存が完了しました。
          </v-card-title>
          <v-card-actions>
            <v-btn
              class="random-btn"
              depressed
              dark
              color="primary"
              @click="saveComp = !saveComp"
              style="margin-bottom:20px;"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Random',
  computed: {
    classList () { return this.$store.state.classInfo }
  },
  data: () => ({
    className: '',
    classId: '',
    classMember: [],
    classData: [],
    rouletteMember: '',
    chosenMember: '',
    alreadyList: [],
    showAlready: false,
    roulette: false,
    showDialog: false,
    saveComp: false
  }),
  methods: {
    select (data) {
      if (!this.className === '') {
        const index = this.classList.indexOf(this.classData)
        this.classList[index].already = this.alreadyList
        this.resetList()
      }
      this.classData = data
      this.className = data.name
      this.classMember = data.mem
      if (data.already) {
        this.alreadyList = data.already
      } else {
        this.alreadyList = []
      }
    },
    resetList () {
      const index = this.classList.indexOf(this.classData)
      this.classList[index].already = this.alreadyList
      this.className = ''
      this.classMember = []
      this.chosenMember = ''
      this.rouletteMember = ''
      this.alreadyList = []
      this.showAlready = false
      this.roulette = false
      this.$store.commit('updateAlready', this.classList[index])
    },
    saveList () {
      const index = this.classList.indexOf(this.classData)
      this.classList[index].already = this.alreadyList
      this.$store.commit('updateAlready', this.classList[index])
      this.saveComp = true
    },
    sleep (msec) {
      return new Promise(function (resolve) {
        setTimeout(function () { resolve() }, msec)
      })
    },
    async randomChoose () {
      this.roulette = true
      const memberSize = this.classMember.length

      const notYet = this.classMember.filter(
        m => this.alreadyList.indexOf(m) === -1
      )

      for (let i = 0; i < 14; i++) {
        const index = Math.floor(Math.random() * memberSize)
        this.rouletteMember = this.classMember[index]
        await this.sleep(100)
      }
      const yIndex = Math.floor(Math.random() * notYet.length)
      this.rouletteMember = notYet[yIndex]
      if (this.alreadyList.includes(this.rouletteMember)) {
        if (this.alreadyList.length === this.classMember.length || notYet.length === 0) {
          this.alreadyList = []
          this.updateAlready()
        }
        await this.randomChoose()
      } else {
        await this.sleep(750)
        this.chosenMember = this.rouletteMember
        this.alreadyList.push(this.rouletteMember)
        this.roulette = false
        this.showDialog = true
        if (this.alreadyList.length >= this.classMember.length || notYet.length === 0) {
          this.alreadyList = []
          this.updateAlready()
        }
      }
    },
    updateAlready () {
      const index = this.classList.indexOf(this.classData)
      this.classList[index].already = this.alreadyList
      this.classData.already = this.alreadyList
    }
  }
}
</script>

<style>
.cardList {
  margin-bottom: 30px;
  max-height: 85vh;
}
.card {
  margin: 5px auto;
}
.randomName {
  text-align: center;
  margin: 10% auto;
}
.random-btn {
  margin: 5px;
}
.chosen {
  font-size: 90px;
}
.chosenList {
  margin: 2px;
}
</style>
