import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import firebase from 'firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    key: 'vue-gauth',
    paths: ['userInfo'],
    storage: window.sessionStorage
  })],
  state: {
    userInfo: {},
    uid: '',
    classInfo: [],
    timerList: {
      aaa: {
        count: 30,
        id: 'aaa',
        name: '30 sec',
        default: 30
      },
      bbb: {
        count: 60,
        id: 'bbb',
        name: '1 min',
        default: 60
      },
      ccc: {
        count: 120,
        id: 'ccc',
        name: '2 min',
        default: 120
      },
      ddd: {
        count: 300,
        id: 'ddd',
        name: '5 min',
        default: 300
      },
      eee: {
        count: 1800,
        id: 'eee',
        name: '30 min',
        default: 1800
      }
    }
  },
  mutations: {
    setUserInfo: function (state, userInfo) {
      state.userInfo = userInfo
    },
    setUserId: function (state, userId) {
      state.uid = userId
    },
    pushClassData: function (state, data) {
      const cData = data.data
      const key = data.key
      cData.key = key
      state.classInfo.push(cData)
      console.log('added to list')
    },
    removeClass: function (state, key) {
      const db = firebase.database()
      const ref = db.ref('class/' + state.uid)
      const hopperRef = ref.child(key)
      hopperRef.remove()
      console.log('remove')
    },
    updateAlready: function (state, data) {
      for (const c of state.classInfo) {
        if (c.id === data.id) {
          const db = firebase.database()
          const ref = db.ref('class/' + state.uid)
          const hopperRef = ref.child(c.key)
          hopperRef.update({
            already: data.already
          })
          console.log('already')
        }
      }
    },
    updateMember: function (state, data) {
      for (const c of state.classInfo) {
        if (c.id === data.id) {
          if (!data.already) {
            data.already = []
          }
          const db = firebase.database()
          const ref = db.ref('class/' + state.uid)
          const hopperRef = ref.child(c.key)
          hopperRef.update({
            mem: data.mem,
            already: data.already
          })
          console.log('member and already')
        }
      }
    },
    updateName: function (state, data) {
      for (const c of state.classInfo) {
        if (c.id === data.id) {
          const db = firebase.database()
          const ref = db.ref('class/' + state.uid)
          const hopperRef = ref.child(c.key)
          hopperRef.update({
            name: data.name
          })
          console.log('already')
        }
      }
    },
    pushTimerData: function (state, payload) {
      const dataList = payload.data
      state.timerList = {
        aaa: {
          count: 30,
          id: 'aaa',
          name: '30 sec',
          default: 30
        },
        bbb: {
          count: 60,
          id: 'bbb',
          name: '1 min',
          default: 60
        },
        ccc: {
          count: 120,
          id: 'ccc',
          name: '2 min',
          default: 120
        },
        ddd: {
          count: 300,
          id: 'ddd',
          name: '5 min',
          default: 300
        },
        eee: {
          count: 1800,
          id: 'eee',
          name: '30 min',
          default: 1800
        }
      }
      for (const key in dataList) {
        state.timerList[dataList[key].id] = dataList[key]
      }
    },
    changeNum (state, payload) {
      state.timerList[payload.id].count += Number(payload.diff)
    },
    resetNum (state, payload) {
      state.timerList[payload.id].count = state.timerList[payload.id].default
    },
    addItem (state, payload) {
      state.timerList[payload.id] = {
        id: payload.id,
        name: payload.name,
        count: payload.count,
        default: payload.count
      }
      firebase.database().ref('timer/' + state.uid).push({
        id: payload.id,
        name: payload.name,
        count: payload.count,
        default: payload.count
      }, () => {
        //
      })
    },
    changeList (state, payload) {
      state.timerList = payload.timerList
    },
    deleteList (state, payload) {
      delete state.timerList[payload.id]
    }
  },
  actions: {
  }
})
