<template>
  <v-container>
    <v-row>
      <v-col cols="12" xs="12">
        <v-card style="margin-top: 25px">
          <v-card-title>
            <h2>修正 / 追加して欲しい要素</h2>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="title"
              label="タイトル"
              clearable
              filled
            ></v-text-field>
            <v-textarea filled v-model="description">
              <template v-slot:label>
                <div>
                  具体的な内容
                </div>
              </template>
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="submit"
              style="margin-right:5%"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="succeed"
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h4">
            送信が完了しました
          </v-card-title>
          <v-card-text>貴重な意見、ありがとうございます。</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="succeed=false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'FeedBack',
  data () {
    return {
      title: null,
      description: null,
      succeed: false
    }
  },
  methods: {
    submit () {
      const title = this.title
      const des = this.description
      firebase.database().ref('feedback').push({
        id: this.$store.state.uid,
        title: title,
        des: des
      }, () => {
        this.title = null
        this.description = null
        this.succeed = true
      })
    }
  }
}
</script>

<style>

</style>
