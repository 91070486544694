<template>
  <v-app>
    <AppHeader/>
    <v-content>
      <router-view/>
    </v-content>
    <AppFooter/>
  </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  created () {
    window.addEventListener('beforeunload', this.confirmSave)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.confirmSave)
  },
  methods: {
    confirmSave (event) {
      event.returnValue = '編集中のものは保存されませんが、よろしいですか？'
    }
  }
}
</script>
