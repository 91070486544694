<template>
  <v-container>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        xs="12"
        sm="5"
        class="cardList overflow-y-auto"
      >
        <v-col
          cols="12"
          xs="12"
          sm="11"
        >
          <v-card
            class="my-4 card"
            v-for="data in classList"
            v-bind:key="data.key"
          >
            <v-card-title>{{ data.name }}</v-card-title>
            <v-card-actions>
              <v-btn
                text
                color="primary"
                @click="select(data)"
              >
                Use This
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        class="mt-10"
        max-height="200"
      >
        <v-col v-if="className != ''">
          <v-btn
            class="random-btn mt-n8 mb-8"
            depressed
            dark
            color="red darken-1"
            @click="tryToRemove = true"
          >
            REMOVE THIS CLASS
          </v-btn>
          <h2>{{ className }} を表示しています</h2>
          <v-btn
            class="random-btn mb-4 font-weight-bold"
            text
            dark
            color="green accent-4"
            @click="tryToEditName = true"
          >
            リスト名を変更
          </v-btn>
          <div>
            <div v-if="!classMember == []">
            <h3>生徒リスト</h3>
              <v-chip
                v-for="m in classMember"
                v-bind:key="m"
                class="ma-2 chosenList"
                close
                @click:close="removeMember(m)"
              >
                {{ m }}
              </v-chip>
            </div>
            <div v-else>
              <h2 style="color: #969696">空だよ</h2>
            </div>
            <h3 class="mt-3">既出リスト</h3>
            <div v-if="!alreadyList == []">
              <v-chip
                v-for="a in alreadyList"
                v-bind:key="a"
                class="ma-2 chosenList"
                close
                @click:close="removeAlready(a)"
              >
                {{ a }}
              </v-chip>
            </div>
            <div v-else>
              <h2 style="color: #969696">空だよ</h2>
            </div>
          </div>
          <v-container>
            <v-row>
              <v-col>
                <v-textarea filled v-model="aname" clearable>
                  <template v-slot:label>
                    <div>
                      生徒名 (改行で区切る)
                    </div>
                  </template>
                </v-textarea>
                <v-btn
                  color="blue darken-1"
                  class="mt-n7"
                  text
                  @click="addMember"
                >
                  Add
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-btn
            class="random-btn mt-4"
            depressed
            color="yellow darken-4"
            @click="deployToDatabase"
          >
            SAVE CHANGES
          </v-btn>
        </v-col>
        <v-col v-else>
          <h1>編集するクラスを<br>選択してください</h1>
          <br>
          <h2>
            追加をしたい場合は、<br>+ボタンを押してください
          </h2>
        </v-col>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showAddingDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="text-h5">
            新規クラスデータを追加
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="NewCname"
                    label="Class Name*"
                    clearable
                    @keydown.enter="addClassData"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="addClassData"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="tryToRemove"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="text-h5">
            削除していいですか？
          </v-card-title>
          <v-card-actions>
            <v-btn
              class="random-btn"
              depressed
              dark
              color="red darken-1"
              @click="deleteClass"
              style="margin-bottom:20px;"
            >
              DELETE
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="tryToRemove = !tryToRemove"
            >
              CANCEL
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="tryToEditName"
        max-width="600px"
      >
        <v-card>
          <v-card-title class="text-h5">
            リスト名を入力
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="EditCname"
                    label="Class Name*"
                    clearable
                    @keydown.enter="editClassData"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="editClassData"
            >
              EDIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="saveComp"
        max-width="300px"
      >
        <v-card>
          <v-card-title class="text-h5">
            保存が完了しました。
          </v-card-title>
          <v-card-actions>
            <v-btn
              class="random-btn"
              depressed
              dark
              color="primary"
              @click="saveComp = !saveComp"
              style="margin-bottom:20px;"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-btn
        fab
        dark
        large
        absolute
        bottom
        right
        color="blue darken-3"
        @click="showAddingDialog = true"
        class="mb-10"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'List',
  computed: {
    classList () { return this.$store.state.classInfo }
  },
  data: () => ({
    aname: null,
    NewCname: null,
    EditCname: null,
    classData: [],
    cname: '',
    className: '',
    classMember: [],
    alreadyList: [],
    removeList: [],
    tryToRemove: false,
    tryToEditName: false,
    showAddingDialog: false,
    saveComp: false
  }),
  methods: {
    removeMember (mName) {
      const cIndex = this.classList.indexOf(this.classData)

      const mIndex = this.classMember.indexOf(mName)
      this.classMember.splice(mIndex, 1)
      this.classList[cIndex].mem = this.classMember
      // this.classData.mem = this.classMember

      if (this.alreadyList) {
        if (this.alreadyList.includes(mName)) {
          const aIndex = this.alreadyList.indexOf(mName)
          this.alreadyList.splice(aIndex, 1)
          this.classList[cIndex].already = this.alreadyList
          // this.classData.already = this.alreadyList
        }
      }
    },
    removeAlready (aName) {
      const cIndex = this.classList.indexOf(this.classData)

      const aIndex = this.alreadyList.indexOf(aName)
      this.alreadyList.splice(aIndex, 1)
      this.classList[cIndex].already = this.alreadyList
      // this.classData.already = this.alreadyList
    },
    deleteClass () {
      const key = this.classData.key
      const cIndex = this.classList.indexOf(this.classData)
      delete this.classList[cIndex]
      this.$store.commit('removeClass', key)
      this.resetList()
      this.getData()
      this.tryToRemove = false
    },
    deployToDatabase () {
      // save to store
      const index = this.classList.indexOf(this.classData)
      console.log(this.classList[index])
      this.$store.commit('updateMember', this.classList[index])
      this.saveComp = true
    },
    addMember () {
      if (!this.aname) { return }
      const newMember = this.aname.split('\n')
      this.aname = null
      // this.getData()
      const cIndex = this.classList.indexOf(this.classData)
      for (const m of newMember) {
        if (m === '') { continue }
        if (this.classMember.includes(m)) { continue }
        this.classMember.push(m)
      }
      this.classList[cIndex].mem = this.classMember
      // this.classData.mem = this.classMember
    },
    editClassData () {
      if (!this.EditCname) { return }
      this.tryToEditName = false
      const index = this.classList.indexOf(this.classData)
      this.classList[index].name = this.EditCname
      this.$store.commit('updateName', this.classList[index])
      this.className = this.EditCname
      this.EditCname = null
    },
    addClassData () {
      if (!this.NewCname) { return }
      this.showAddingDialog = false
      this.getData()
      console.log(this.classList)
      console.log(this.classList.length)
      var cid
      if (!this.classList) {
        cid = 0
      } else {
        cid = this.classList.length
      }
      firebase.database().ref('class/' + this.$store.state.uid).push({
        id: cid,
        name: this.NewCname,
        mem: ['A', 'B', 'C', 'D'],
        already: []
      }, () => {
        this.NewCname = null
      })
    },
    getData () {
      const db = firebase.database()
      const ref = db.ref('class/' + this.$store.state.uid)

      ref.once('value', (snap) => {
        this.$store.state.classInfo = []
        const cList = snap.val()
        for (const key in cList) {
          const data = cList[key]
          this.$store.commit('pushClassData', { data, key })
        }
      }, (err) => {
        console.error(err)
      })
    },
    select (data) {
      if (!this.className === '') {
        this.resetList()
      }
      this.classData = data
      this.className = data.name
      this.classMember = data.mem
      this.alreadyList = data.already
      // console.log(this.alreadyList)
    },
    resetList () {
      this.className = ''
      this.classMember = []
      this.alreadyList = []
    }
  }
}
</script>

<style>

</style>
