export default `
# How To Use
左上に表示されてる [≡] を押すと、左側に出てるナビゲーションバーが消えます。

## はじめに
自分のアカウントでログインされていることを確認します

:::error
クラス情報はGoogleアカウントと結びつけてあるため、  
正常にアカウント情報が表示されていない状態だと、  
クラス情報は表示されません。
:::

## 新規クラスを作成
PCは上のメニューバーにある [Edit] と書かれた場所を、
スマホ版は左上にある [≡] を押し、 [Edit] をクリックし、
エディットページに移動します。

画面右下にあるこのボタンをクリック
![image.png](https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/974385/038b93b7-974c-2df2-0201-35d04dcc9906.png)

出てくるダイアログに、追加したいクラスの名前を入力、Addを押します。
![image.png](https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/974385/11bbfac9-c64a-bfde-8120-827f75cf7ac7.png)


## 生徒一覧の編集
1. EDITページに行く
![image.png](https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/974385/94bb7a5d-6dc2-7b4a-19ab-0e3bbb1a3f9c.png)
編集主体クラスカードの [USE THIS] をクリックします。
そうすると、このように表示が切り替わります。
![image.png](https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/974385/ffced67d-9059-8196-25ae-bb004e8f4377.png)

初期値の [A, B, C, D]が入っているので、全部削除します。
そのご、追加したい名前を [生徒名] の中に記述し、Enterキーか [Add] ボタンを押し、追加します。
![image.png](https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/974385/8ce0e8f5-d938-3e48-ccb3-ce7cf2087fb0.png)

:::warning
[SAVE CHANGES] ボタンを押さないと、保存されません。
:::

## ランダム抽出
1. RANDOMページに行く
2. クラスカードの **USE THIS** をクリック
そうすると、このようにボタンが四つ表示されると思います。

![image.png](https://qiita-image-store.s3.ap-northeast-1.amazonaws.com/0/974385/f542152d-1ebc-106a-4830-ecb33661e5e8.png)

- **CHOOSE**：ルーレット開始 ランダムで誰かが選ばれる
- **SAVE**：誰が選ばれたかの情報を保存する
- **SHOW LIST**：既出リストが表示される （誰が選ばれたか一目でわかる）
- **HIDE LIST**：既出リストを隠す


::: warning
**SAVE** ボタンを押さないと、選ばれた人一覧は保存されません
:::

既出リストは、全員選ばれるとリセットされます。

`
