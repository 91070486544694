export default {
  menuItems: [
    {
      name: 'HOME',
      url: '/',
      icon: 'mdi-home'
    },
    {
      name: 'RANDOMER',
      url: '/random',
      icon: 'mdi-shuffle',
      subLinks: [
        {
          name: 'SHUFFLE',
          url: '/random',
          icon: 'mdi-shuffle'
        },
        {
          name: 'EDIT',
          url: '/edit',
          icon: 'mdi-pencil'
        },
        {
          name: 'DOCS',
          url: '/docs',
          icon: 'mdi-text-box'
        }
      ]
    },
    {
      name: 'Timer',
      url: '/timer',
      icon: 'mdi-clock'
    },
    {
      name: 'FEEDBACK',
      url: '/feedback',
      icon: 'mdi-rss'
    }
    // {
    //   name: 'LABO',
    //   url: '/about'
    // }
  ]
}
