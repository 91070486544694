<template>
  <div>
    <markdown-it-vue class="md-body" :content="content" :options="options" />
  </div>
</template>

<script>
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import Doc from '@/pages/Doc.js'

export default {
  name: 'Docs',
  components: {
    MarkdownItVue
  },
  data () {
    return {
      content: Doc,
      options: {
        markdownIt: {
          linkify: true
        },
        linkAttributes: {
          attrs: {
            target: '_self',
            rel: 'noopener'
          }
        }
      }
    }
  }
}
</script>

<style>
.md-body {
  margin: 10px 30px;
}
</style>
